<template>
  <el-dialog
    :title="type == 1 ? '设置' : '批量设置'"
    :visible.sync="showDialog"
    width="700px"
    style="min-height: 510px"
    top="6vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        label-position="right"
        label-width="140px"
        label-suffix=":"
      >
        <template v-if="type == 1">
          <el-form-item label="商品" prop="">
            <el-input v-model="form.goods_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="品牌" prop="">
            <el-input v-model="form.brand_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="换算" prop="">
            <el-input v-model="form.goods_convert" disabled></el-input>
          </el-form-item>
        </template>
        <el-form-item label="提成方式" prop="">
          <el-select v-model="form.way" placeholder="请选择提成方式">
            <el-option
              v-for="(i, idx) in commissionTypeSelect"
              :key="idx"
              :value="i.id"
              :label="i.title"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 数量核算方式 -->
        <template v-if="(form.way >= 4 && form.way <= 6) || form.way >= 10">
          <el-form-item
            :label="
              form.way >= 4 && form.way <= 6
                ? '数量核算方式'
                : '价格单位核算方式'
            "
            prop=""
          >
            <el-select
              v-model="form.unit_type"
              clearable
              placeholder="核算方式"
            >
              <el-option
                v-for="(i, idx) in numTypeSelect"
                :key="idx"
                :value="i.id"
                :label="i.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
        <!-- 多选框 -->
        <el-form-item label="" prop="">
          <el-checkbox v-model="form.is_back" :true-label="1" :false-label="2">
            退货参与提成
          </el-checkbox>
          <el-checkbox
            v-if="form.way >= 4 && form.way <= 9"
            v-model="form.is_gift"
            :true-label="1"
            :false-label="2"
          >
            赠品参与提成
          </el-checkbox>
        </el-form-item>

        <!-- 成本计算方式 -->
        <!-- <template>
          <el-form-item label="成本计算方式" prop="">
            <el-select v-model="form.unit_type" placeholder="核算方式">
              <el-option
                v-for="(i, idx) in numTypeSelect"
                :key="idx"
                :value="i.id"
                :label="i.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </template> -->
        <!-- <template v-if="form.way == "></template>
        <template v-if="form.way == "></template>
        <template v-if="form.way == "></template>
        <template v-if="form.way == "></template>
        <template v-if="form.way == "></template> -->
        <template v-if="form.way == 1 || form.way == 4 || form.way == 7">
          <el-form-item
            :label="
              form.way == 1 || form.way == 7
                ? isXiao == 2
                  ? '销售提成比例'
                  : '送货提成比例'
                : isXiao == 2
                ? '销售提成金额'
                : '送货提成金额'
            "
            prop=""
          >
            <el-input v-model="form.sale_price">
              <template slot="append">
                {{ form.way == 1 || form.way == 7 ? '%' : '元' }}
              </template>
            </el-input>
          </el-form-item>
          <el-form-item
            v-if="form.is_back != 2"
            :label="
              form.way == 1 || form.way == 7 ? '退货提成比例' : '退货提成金额'
            "
          >
            <el-input v-model="form.back_price">
              <template slot="append">
                {{ form.way == 1 || form.way == 7 ? '%' : '元' }}
              </template>
            </el-input>
          </el-form-item>
        </template>
        <!-- 2 3 5 6 8 9 10 11 -->
        <template v-if="showTable">
          <el-table stripe :data="list">
            <!-- <el-table-column
              v-for="(item, tableIndex) in columns"
              :key="tableIndex"
              :prop="item.prop"
              :label="item.label"
              width=""
              align="center"
            ></el-table-column> -->
            <el-table-column
              prop="start"
              width="340"
              :label="tableHeader"
              align="center"
            >
              <template #default="{ row, $index }">
                <el-input
                  v-model="row.start"
                  style="width: 180px"
                  onkeyup="value=value.replace(/[^\d.]/g,'')"
                  type="text"
                  @input="tableNum($event, $index, row)"
                >
                  <template slot="prepend">
                    <span
                      v-if="form.way == 3 || form.way == 6 || form.way == 9"
                      style="font-size: 14px; color: red"
                    >
                      &gt;
                    </span>
                    <span v-else style="margin-left: 5px; font-size: 14px">
                      ≥
                    </span>
                  </template>
                </el-input>
                <span
                  v-if="form.way == 3 || form.way == 6 || form.way == 9"
                  style="margin-left: 5px; font-size: 14px"
                >
                  小于等于
                </span>
                <span v-else style="margin-left: 5px; font-size: 14px">
                  小于
                </span>
                <span style="margin-left: 5px">
                  {{ row.end || '' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="sale_price"
              :label="
                isXiao == 2
                  ? '销售提成(' + isYuan + ')'
                  : '送货提成(' + isYuan + ')'
              "
              align="center"
            >
              <template #default="{ row }">
                <el-input
                  v-model="row.sale_price"
                  style="width: 100%"
                  type="number"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              v-if="form.is_back == 1"
              prop="back_price"
              :label="'退货提成(' + isYuan + ')'"
              align="center"
            >
              <template #default="{ row }">
                <el-input
                  v-model="row.back_price"
                  style="width: 100%"
                  type="number"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              align="center"
              label="操作"
              fixed="right"
              width="110px"
            >
              <template #default="{ $index, row }">
                <el-button
                  type="text"
                  @click.native.prevent="handleAddRow($index, row)"
                >
                  添加
                </el-button>
                <el-button
                  type="text"
                  @click.native.prevent="handleDeleteRow($index, row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <p style="margin-top: 10px">
          提示：提成金额或比例输入负数代表扣钱，例如退货要扣1%提成，输入-1即可
        </p>
      </el-form>
    </div>
    <div class="dialog-footer">
      <el-button type="primary" @click="handleSubmit">确定</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  export default {
    name: '',
    components: {},
    data() {
      return {
        isXiao: 2, // 2销售 3送货
        title: '标题',
        type: 1, //1 设置 2批量设置
        showDialog: false,
        form: {
          info_id: '', //方案id
          detail_id: '', //商品id，多个数组格式
          way: 1, //提成方式
          is_back: 1, //退货是否参与1是2否
          is_gift: 2, //赠品是否参与1是2否 (4-9用)
          unit_type: null, //包装单位1基本单位2大单位(4-6)
          detail_data: '', //区间范围 (2,3,5,6,8,9,10,11用)和下面的正好相反
          sale_price: '', //销售提成(1,4,7用，和区间范围只用一个)
          allot_price: '', //调拨提成(1,4,7用，和区间范围只用一个)
          back_price: '', //退货提成(1,4,7用，和区间范围只用一个)
          goods_name: '',
          brand_name: '',
        },
        commissionTypeSelect: [
          {
            id: 1,
            title: '销售额百分比',
          },
          {
            id: 2,
            title: '销售额变化百分比',
          },
          {
            id: 3,
            title: '销售额分段变化百分比',
          },
          {
            id: 4,
            title: '销售数量每件固定额',
          },
          {
            id: 5,
            title: '按销量阶梯增加提成',
          },
          {
            id: 6,
            title: '按销售数量分段变化每件提成金额',
          },
          {
            id: 7,
            title: '利润额百分比',
          },
          {
            id: 8,
            title: '利润额变化百分比',
          },
          {
            id: 9,
            title: '利润额分段变化百分比',
          },
          {
            id: 10,
            title: '销售价格分段提成金额',
          },
          {
            id: 11,
            title: '销售价格分段百分比提成',
          },
        ],
        // 数量核算方式下拉
        numTypeSelect: [
          { id: 1, name: '使用基本单位核算数量' },
          {
            id: 2,
            name: '使用大包装单位核算数量',
          },
        ],
        list: [
          {
            sale_price: '',
            back_price: '',
            allot_price: '',
            start: '',
          },
        ],
      }
    },
    computed: {
      // 表格显示销售额还是销售数量。。
      tableHeader() {
        if (this.form.way == 2 || this.form.way == 3) {
          return '销售额区间范围'
        } else if (this.form.way == 5 || this.form.way == 6) {
          return '销售数量区间范围'
        } else if (this.form.way == 8 || this.form.way == 9) {
          return '利润额区间范围'
        } else {
          return '销售价格区间范围'
        }
      },
      // 是否显示表格
      showTable() {
        // 2 3 5 6 8 9 10 11
        if (
          this.form.way == 2 ||
          this.form.way == 3 ||
          this.form.way == 5 ||
          this.form.way == 6 ||
          this.form.way == 8 ||
          this.form.way == 9 ||
          this.form.way == 10 ||
          this.form.way == 11
        ) {
          return true
        } else {
          return false
        }
      },
      // 表头显示元还是%
      isYuan() {
        if (
          this.form.way == 2 ||
          this.form.way == 3 ||
          this.form.way == 8 ||
          this.form.way == 9 ||
          this.form.way == 11
        ) {
          return '%'
        } else {
          return '元'
        }
      },
      // 表格 动态col
      // columns() {
      //   let col = [
      //     {
      //       prop: '',
      //       label: '净销售额区间范围',
      //       width: '',
      //     },
      //     {
      //       prop: '',
      //       label: '销售提成(%)',
      //       width: '',
      //     },
      //     {
      //       prop: '',
      //       label: '退货提成(%)',
      //       width: '',
      //     },
      //   ]
      //   // 勾选退货参与提成
      //   if (this.form.is_back == 1) {
      //     return col
      //   } else {
      //     col[0].label = '销售额区间范围'
      //     col.splice(2, 1)
      //     return col
      //   }
      // },
    },
    watch: {
      'form.way'(v) {
        if (v == 7 || v == 8 || v == 9) {
          this.form.is_gift = 1
        } else {
          this.form.is_gift = 2
        }
      },
      showDialog(v) {
        if (!v) {
          this.list = [
            {
              sale_price: '',
              back_price: '',
              allot_price: '',
              start: '',
            },
          ]
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      // 提交
      handleSubmit() {
        let form = {
          ...this.form,
          ...{ detail_data: JSON.stringify(this.list) },
        }
        form.is_gift = form.is_gift == 0 ? 2 : form.is_gift
        form.is_back = form.is_back == 0 ? 2 : form.is_back
        postAction('/taskAdmin/extract-info-detail/set-between', form).then(
          (r) => {
            this.$message.success('设置成功')
            this.$emit('refresh')
            this.close()
          }
        )
      },
      close() {
        this.showDialog = false
      },
      // 尾部显示表格下一行的数字
      tableNum(val, idx, row) {
        if (idx != 0 && !isNaN(val)) {
          console.log('vv', val, row)
          this.$set(this.list[idx - 1], 'end', val)
        }
      },
      addRow() {
        this.list.push({
          sale_price: '',
          back_price: '',
          allot_price: '',
          start: '',
          end: '',
        })
      },
      handleAddRow(index, row) {
        this.addRow()
      },
      handleDeleteRow(index, row) {
        if (this.list.length == 1) {
          this.$message.error('不能删除最后一行')
          this.list = []
          this.addRow()
        } else {
          this.list.splice(index, 1)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .orderTest-container {
    ::v-deep {
      .el-input {
        width: 320px;
      }
    }
  }
</style>
