<template>
  <div class="orderTest-container">
    <!-- NAME[epic=绩效] 管理提成方案 -->
    <el-radio-group v-model="type">
      <el-radio-button :label="2">销售提成方案</el-radio-button>
      <el-radio-button :label="3">送货提成方案</el-radio-button>
      <!-- <el-radio-button :label="4">调拨提成方案</el-radio-button> -->
    </el-radio-group>
    <div class="content">
      <div class="l">
        <div class="top">
          <el-button size="mini" type="primary" @click="handleCreatePlan">
            添加方案
          </el-button>
          <el-button size="mini" type="warning" @click="handleEditPlan">
            编辑方案
          </el-button>
        </div>
        <div class="left-table">
          <el-table
            stripe
            :data="leftList"
            height="480px"
            highlight-current-row
            @cell-click="leftTableCellClick"
          >
            <el-table-column
              prop="title"
              :label="xiaosong"
              width=""
              align="center"
            ></el-table-column>
          </el-table>
        </div>
        <div class="bottom">
          <el-button type="danger" @click="handleDeletePlan">
            删除方案
          </el-button>
        </div>
      </div>
      <div class="r">
        <div class="form" style="margin-left: 10px">
          <brand-select
            ref="brandSelect"
            :duoxuan="true"
            style="display: inline-block; width: 140px; margin: 0 10px"
            @brand-select-change="brandChange"
          ></brand-select>
          <el-input
            v-model="form.goods_name"
            placeholder="请输入商品名称、简拼"
            style="display: inline-block; width: 160px"
          ></el-input>
          <el-button
            type="primary"
            style="margin-left: 10px"
            @click="handleQuery"
          >
            查询已添加商品
          </el-button>
          <el-button
            type="primary"
            style="margin-left: 10px"
            @click="handleAdd"
          >
            添加商品
          </el-button>
          <el-button
            type="success"
            style="margin-left: 10px"
            @click="handleExportAll"
          >
            导出全部商品
          </el-button>
          <el-button
            type="success"
            style="margin-left: 10px"
            @click="handleExportE"
          >
            导出提成商品
          </el-button>
          <el-button
            type="info"
            style="margin-left: 10px"
            @click="handleImportE"
          >
            导入提成商品
          </el-button>
        </div>
        <div class="table" style="margin-left: 20px">
          <el-table
            ref="rightTable"
            v-loading="rightLoading"
            stripe
            :data="rightList"
            height="480px"
            row-key="id"
            @select="handleSelectRow"
            @select-all="selectAll"
          >
            <!-- 选择框 -->
            <el-table-column
              align="center"
              type="selection"
              width="50"
              reserve-selection
            ></el-table-column>
            <el-table-column
              v-for="(item, tableIndex) in columns"
              :key="tableIndex"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              align="center"
            >
              <template #default="{ row }">
                <div
                  v-if="item.label == '销售提成' || item.label == '送货提成'"
                >
                  <div v-if="row.between.length > 0">
                    {{ ticheng(row.between, row.is_yuan == 1 ? '元' : '%') }}
                  </div>
                  <div v-else>
                    {{ row.sale_price }}
                    <span v-show="row.sale_price">
                      {{ row.is_yuan == 1 ? '元' : '%' }}
                    </span>
                  </div>
                </div>
                <div v-if="item.label == '退货提成'">
                  <div v-if="row.between.length > 0">
                    {{ ticheng2(row.between, row.is_yuan == 1 ? '元' : '%') }}
                  </div>
                  <div v-else>
                    {{ row.back_price }}
                    <span v-show="row.back_price">
                      {{ row.is_yuan == 1 ? '元' : '%' }}
                    </span>
                  </div>
                </div>
                <div v-if="item.label == '商品名称'">
                  <goods-search
                    ref="goodsSearch"
                    :f-key="row.goods_name"
                    @add-rows="addRows($event, row)"
                    @select-goods-all="goodsSelect($event, row)"
                  ></goods-search>
                </div>
                <div v-else>{{ row[item.prop] }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              align="center"
              label="操作"
              fixed="right"
              min-width="120px"
            >
              <template #default="{ $index, row }">
                <div v-if="row.goods_id">
                  <el-button
                    type="text"
                    @click.native.prevent="handleEdit($index, row)"
                  >
                    设置
                  </el-button>
                  <el-button
                    type="text"
                    @click.native.prevent="handleDelete($index, row)"
                  >
                    删除
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="botton-tools">
            <div class="btn">
              <el-button type="primary" @click="handleBatchSet">
                批量设置
              </el-button>
              <el-button type="danger" @click="handleBatchDelete">
                批量删除
              </el-button>
            </div>
            <el-pagination
              :current-page="form.pageNo"
              :layout="layout"
              :page-size="form.pageSize"
              :total="total"
              background
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <add-plan ref="addPlan" @refresh="leftTableData(type)"></add-plan>
    <setPlan ref="setPlan" @refresh="fetchData"></setPlan>
    <import-tpl ref="importTpl"></import-tpl>
    <GoodsChooseDialog
      ref="GoodsChooseDialog"
      @add-rows="addRows"
    ></GoodsChooseDialog>
  </div>
</template>
<script>
  import { commissionPlanSelect } from '@/api/performance'
  import { postAction, downloadFile } from '@/api/Employee'
  import BrandSelect from '@/baseComponents/brandSelect'
  import addPlan from './components/addPlan'
  import setPlan from './components/setPlan'
  import goodsSearch from '@/baseComponents/goodsSearch'
  import importTpl from '@/views/index/components/importTpl.vue'
  import GoodsChooseDialog from '@/baseComponents/goodsChooseDialog'
  export default {
    name: 'CommissionPlanManagement',
    components: {
      BrandSelect,
      addPlan,
      setPlan,
      goodsSearch,
      importTpl,
      GoodsChooseDialog,
    },
    data() {
      return {
        type: 2,
        leftList: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          pageSize: 10, //条数
          pageNo: 1, //页数
          info_id: '', //方案id
          brand_id: '', //品牌id
          goods_id: '', //商品id
          goods_name: '', //商品名称
        },
        rightList: [],
        rightLoading: false,
        checkList: [
          '品牌',
          '商品名称',
          '规格',
          '提成方式',
          '赠品与退货',
          '销售提成',
          '退货提成',
        ],
        // 勾选中的row
        selectRow: [],
        columns: [
          {
            order: 1,
            label: '品牌',
            prop: 'brand_name',
            width: '',
          },
          {
            order: 2,
            label: '商品名称',
            prop: 'goods_name',
            width: '200',
          },
          {
            order: 3,
            label: '规格',
            prop: 'specs',
            width: '',
          },
          {
            order: 4,
            label: '提成方式',
            prop: 'way_text',
            width: '',
          },
          {
            order: 5,
            label: '赠品',
            prop: 'is_gift_text',
            width: '',
          },

          {
            order: 6,
            label: '退货',
            prop: 'is_back_text',
            width: '',
          },
          {
            order: 6,
            label: '销售提成',
            prop: '',
            width: '',
          },
          {
            order: 7,
            label: '退货提成',
            prop: '',
            width: '',
          },
        ],
        currentPlan: null,
      }
    },
    computed: {
      xiaosong() {
        if (this.type == 2) {
          return '销售提成方案名称'
        } else if (this.type == 3) {
          return '送货提成方案名称'
        } else {
          return ''
        }
      },
      xiaosong2() {
        if (this.type == 2) {
          return '销售提成'
        } else if (this.type == 3) {
          return '送货提成'
        } else {
          return ''
        }
      },
    },
    watch: {
      type(v) {
        this.leftTableData(v)
        this.form = {
          pageSize: 10, //条数
          pageNo: 1, //页数
          info_id: '', //方案id
          brand_id: '', //品牌id
          goods_id: '', //商品id
          goods_name: '', //商品名称
        }
        if (v == 2) {
          // return '销售提成'
          this.columns[6].label = '销售提成'
        } else if (v == 3) {
          // return '送货提成'
          this.columns[6].label = '送货提成'
        } else {
          return ''
        }
        this.$refs.brandSelect.resetForm()
        this.rightList = []
      },
    },
    created() {},
    mounted() {
      // 左表格数据获取 方案名称
      this.leftTableData(this.type)
    },
    methods: {
      // 右表格数据获取
      async fetchData(addRow = true) {
        this.rightLoading = true
        try {
          let { data, totalCount } = await postAction(
            '/taskAdmin/extract-info-detail/index',
            this.form
          )
          this.rightList = data
          if (addRow) {
            this.addRow()
          } else {
            this.form.pageNo = this.form.pageNo - 1
            this.fetchData()
          }
          // this.addRow()
          this.total = totalCount
          this.rightLoading = false
          this.selectRow = []
          this.$refs.rightTable.clearSelection()
        } catch (e) {
          this.rightLoading = false
        }
      },
      // 添加一个空商品
      addRow() {
        this.rightList.push({
          id: null,
          goods_name: '',
          specs: '',
          brand_id: null,
          brand_name: '',
          way: null,
          way_text: '',
          goods_convert: null,
          is_back_text: '',
          is_gift_text: '',
          is_yuan: null,
          between: [],
        })
        this.$set(this.rightList[this.rightList.length - 1], 'goods_name', '')
      },
      addRows(val, row) {
        let goods_id = []
        val.forEach((i) => {
          if (i.goods_id) goods_id.push(i.goods_id)
        })
        // 新加商品

        postAction('/taskAdmin/extract-info-detail/goods-add', {
          info_id: this.form.info_id,
          goods_id,
        }).then((r) => {
          this.$message.success('商品添加成功')
          // this.$set(
          //   this.rightList[this.rightList.length - 1],
          //   'goods_name',
          //   ''
          // )
          // this.$refs.goodsSearch.goods_keyword = ''
          this.fetchData()
        })
      },
      goodsSelect(val, row) {
        row.goods_name = val.goods_name
        if (val.goods_id > 0) {
          if (row.id) {
            // 修改
            // 新加商品
            postAction('/taskAdmin/extract-info-detail/goods-update', {
              detail_id: row.id,
              goods_id: val.goods_id,
            }).then((r) => {
              this.$message.success('商品更新成功')
              this.fetchData()
            })
          } else {
            // 新加商品
            postAction('/taskAdmin/extract-info-detail/goods-add', {
              info_id: this.form.info_id,
              goods_id: val.goods_id,
            }).then((r) => {
              this.$message.success('商品添加成功')
              // this.$set(
              //   this.rightList[this.rightList.length - 1],
              //   'goods_name',
              //   ''
              // )
              // this.$refs.goodsSearch.goods_keyword = ''
              this.fetchData()
            })
          }
          console.log('商品下拉选zhogn ', val, row)
          // Object.assign(row, val)
          // this.addRow()
        }

        /**
         *
         // 新加商品
          postAction('/taskAdmin/extract-info-detail/goods-add', {
            info_id: this.form.info_id,
            goods_id: val.goods_id,
          }).then((r) => {
            this.$message.success('商品添加成功')
            this.fetchData()
          })
         */
      },
      // 表格提成区间计算
      ticheng(arr, yuan) {
        console.log('arr', arr)
        let str = ''
        arr.forEach((i) => {
          str += i.start + ' - ' + i.end + ' ' + i.sale_price + yuan + '\n'
        })
        return str
      },
      ticheng2(arr, yuan) {
        console.log('arr', arr)
        let str = ''
        arr.forEach((i) => {
          str += i.start + ' - ' + i.end + ' ' + i.back_price + yuan + '\n'
        })
        return str
      },
      // 左表格数据获取 方案名称
      // 类别2业务员提成3送货员提成4调拨提成
      leftTableData(type = 2) {
        commissionPlanSelect({ pageNo: 1, pageSize: -1, type }).then((r) => {
          this.leftList = r.data
        })
      },
      // 左表格 点击 行
      leftTableCellClick(row) {
        console.log('', row)
        this.form.pageNo = 1
        // 清空选中
        this.$refs.rightTable.clearSelection()
        this.selectRow = []
        this.currentPlan = row
        this.form.info_id = row.id
        this.fetchData()
      },

      // 添加方案
      handleCreatePlan() {
        this.$refs.addPlan.form.type = this.type
        this.$refs.addPlan.type = 1
        this.$refs.addPlan.showDialog = true
      },
      // 编辑方案
      handleEditPlan() {
        if (this.form.info_id) {
          this.$refs.addPlan.form = JSON.parse(JSON.stringify(this.currentPlan))
          this.$refs.addPlan.type = 2
          this.$refs.addPlan.showDialog = true
        } else {
          this.$message.error('请先选择方案')
        }
      },
      // 删除方案
      handleDeletePlan() {
        if (this.form.info_id) {
          this.$confirm('确定删除此项吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              postAction('/taskAdmin/extract-info/del', {
                id: this.currentPlan.id,
              }).then((res) => {
                this.$message.success('删除成功')
                this.leftTableData(this.type)
                this.rightList = []
              })
            })
            .catch(() => {
              console.log('no')
            })
        } else {
          this.$message.error('请先选择方案')
        }
      },
      // 查询
      handleQuery() {
        this.form.pageSize = 10
        this.form.pageNo = 1
        this.$refs.rightTable.clearSelection()
        this.fetchData()
      },
      // 导出全部商品
      handleExportAll() {
        downloadFile(
          '/taskAdmin/extract-info-detail/goods-base-export',
          '全部商品.xlsx'
        )
      },
      // 导出提成商品
      handleExportE() {
        if (this.form.info_id) {
          downloadFile(
            '/taskAdmin/extract-info-detail/goods-export',
            this.currentPlan.title + '.xlsx',
            this.form
          )
        } else {
          this.$message.error('请选择提成方案')
        }
      },
      // 导入提成商品
      handleImportE() {
        if (this.form.info_id) {
          this.$refs.importTpl.data.type = 7
          this.$refs.importTpl.data.check_id = this.form.info_id
          this.$refs.importTpl.title = '导入商品'
          this.$refs.importTpl.showDialog = true
        } else {
          this.$message.error('请选择方案')
        }
      },
      brandChange(val) {
        this.form.brand_id = val
      },

      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      // 批量设置
      handleBatchSet() {
        if (this.selectRow.length > 0) {
          let ids = []
          this.selectRow.forEach((i) => {
            ids.push(i.id)
          })
          console.log('asd', ids)
          this.$refs.setPlan.showDialog = true
          this.$refs.setPlan.type = 2
          this.$refs.setPlan.isXiao = this.type

          this.$refs.setPlan.form.detail_id = ids
          this.$refs.setPlan.form.info_id = this.form.info_id
        } else {
          this.$message.error('请选择商品')
        }
      },
      // 批量删除
      handleBatchDelete() {
        if (this.selectRow.length > 0) {
          this.$confirm('确定删除选中项吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              let ids = []
              this.selectRow.forEach((i) => {
                ids.push(i.id)
              })
              postAction('/taskAdmin/extract-info-detail/goods-del', {
                info_id: this.form.info_id,
                detail_id: ids,
              }).then(() => {
                this.$message.success('删除成功')
                this.$refs.rightTable.clearSelection()
                this.fetchData()
              })
            })
            .catch(() => {
              console.log('no')
            })
        } else {
          this.$message.error('请选择商品')
        }
      },
      handleEdit(index, row) {
        console.log('row', row)
        this.$refs.setPlan.showDialog = true
        this.$refs.setPlan.type = 1
        this.$refs.setPlan.isXiao = this.type

        let is_back = row.is_back == 0 ? 1 : row.is_back
        this.$refs.setPlan.form = {
          ...row,
          ...{
            info_id: this.form.info_id,
            detail_id: row.id,
            way: row.way,
            list: row.between,
            unit_type: row.unit_type,
            is_back: is_back,
          },
        }

        if (row.between.length !== 0) this.$refs.setPlan.list = row.between
      },
      handleDelete(index, row) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            postAction('/taskAdmin/extract-info-detail/goods-del', {
              info_id: this.form.info_id,
              detail_id: row.id,
            }).then((res) => {
              this.$message.success('删除成功')

              this.fetchData(false)
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      // 勾选表格
      handleSelectRow(select) {
        this.selectRow = select
        console.log('selectRow', this.selectRow)
      },
      // 用户全选
      selectAll(select) {
        console.log('rr', select)
        this.selectRow = select
        // select.forEach((row) => {
        //   if (!row.id) {
        //     this.$refs.rightTable.toggleRowSelection(row, false)
        //   }
        // })
      },
      handleAdd() {
        if (this.currentPlan) {
          this.$refs.GoodsChooseDialog.showDialog = true
        } else {
          this.$message.error('请先选择方案')
        }
      },
      // addRowsD(val) {
      //   console.log('addRows', val)
      // },
      setInput(val) {
        console.log('setInput', val)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 10px;
  }
  .l {
    width: 18%;
  }
  .top {
    margin-top: 5px;
    text-align: center;
  }
  .left-table {
    margin: 10px 0;
  }
  .bottom {
    text-align: center;
  }
  .r {
    width: 82%;
  }
  .form {
    margin: 5px 0;
  }
  .botton-tools {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    ::v-deep {
      .el-pagination {
        margin: 0;
      }
    }
  }
</style>
