<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="600px"
    top="15vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        label-position="right"
        label-width="120px"
        label-suffix=":"
        :rules="rules"
      >
        <el-form-item label="方案名称" prop="title">
          <el-input v-model="form.title" style="width: 305px"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">{{ typeName }}</el-form-item>
        <el-form-item label="提成范围" prop="">
          <el-select
            v-model="form.order_type"
            multiple
            placeholder="全部下单方式(可多选)"
            style="width: 150px"
          >
            <el-option
              v-for="(i, idx) in fanwei2"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
          <el-select
            v-model="form.goods_type"
            multiple
            placeholder="全部销售类型(可多选)"
            style="width: 150px; margin-left: 5px"
          >
            <el-option
              v-for="(i, idx) in fanwei1"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="限已结清" prop="is_clear">
          <el-radio v-model="form.is_clear" :label="1">是</el-radio>
          <el-radio v-model="form.is_clear" :label="2">否</el-radio>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin: 0 auto; text-align: center">
      <el-button type="primary" @click="handleSubmit">确定</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'

  export default {
    name: 'Add',
    components: {},
    data() {
      return {
        showDialog: false,
        type: 1, //1添加 2编辑
        order_type: [],
        goods_type: [],
        fanwei1: [],
        fanwei2: [],
        form: {
          id: '', //:提成id(修改需要)
          title: '', //:方案名称
          type: 1, //:类别1全部2业务员提成3送货员提成
          is_clear: 1, //:是否按回款提成1是2否
        },
        rules: {
          title: [
            {
              required: true,
              message: '请输入方案名称',
              tigger: 'blur',
            },
          ],
        },
      }
    },
    computed: {
      typeName() {
        if (this.form.type == 2) {
          return '销售提成方案'
        } else if (this.form.type == 3) {
          return '送货提成方案'
        } else if (this.form.type == 4) {
          return '调拨提成方案'
        } else {
          return 'xx'
        }
      },
      title() {
        if (this.type == 1) {
          return '添加方案'
        } else {
          return '编辑方案'
        }
      },
    },
    watch: {
      showDialog(v) {
        if (!v) {
          this.type = 1
          this.form = {
            id: '', //:提成id(修改需要)
            title: '', //:方案名称
            type: '', //:类别1全部2业务员提成3送货员提成
            is_clear: 1, //:是否按回款提成1是2否
          }
        }
      },
    },
    created() {
      this.initSelect()
      //       /taskAdmin/extract-info/goods-type
      // 提成商品类型
      // {
      //     "id": "",
      //     "name": "",
      //     "disabled": "这个字段不用管"
      // }
      // /taskAdmin/extract-info/order-type
      // 提成订单类型
      // {
      //     "id": "",
      //     "name": ""
      // }
    },
    mounted() {},
    methods: {
      // 初始化  范围下拉
      async initSelect() {
        let res = await postAction('/taskAdmin/extract-info/goods-type')
        this.fanwei1 = res
        let res2 = await postAction('/taskAdmin/extract-info/order-type')
        this.fanwei2 = res2
      },
      handleSubmit() {
        this.$refs.form.validate((v) => {
          if (v) {
            if (this.form.goods_type)
              this.form.goods_type = this.form.goods_type.join(',')
            if (this.form.order_type)
              this.form.order_type = this.form.order_type.join(',')
            console.log(this.form)
            postAction('/taskAdmin/extract-info/modify', this.form).then(
              (r) => {
                this.$message.success(this.type == 1 ? '添加成功' : '修改成功')
                this.$emit('refresh')
                this.close()
              }
            )
          }
        })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
