var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticStyle: { "min-height": "510px" },
      attrs: {
        title: _vm.type == 1 ? "设置" : "批量设置",
        visible: _vm.showDialog,
        width: "700px",
        top: "6vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-position": "right",
                "label-width": "140px",
                "label-suffix": ":",
              },
            },
            [
              _vm.type == 1
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "商品", prop: "" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.goods_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "goods_name", $$v)
                            },
                            expression: "form.goods_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "品牌", prop: "" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.brand_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "brand_name", $$v)
                            },
                            expression: "form.brand_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "换算", prop: "" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.goods_convert,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "goods_convert", $$v)
                            },
                            expression: "form.goods_convert",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "提成方式", prop: "" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择提成方式" },
                      model: {
                        value: _vm.form.way,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "way", $$v)
                        },
                        expression: "form.way",
                      },
                    },
                    _vm._l(_vm.commissionTypeSelect, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.title },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              (_vm.form.way >= 4 && _vm.form.way <= 6) || _vm.form.way >= 10
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.form.way >= 4 && _vm.form.way <= 6
                              ? "数量核算方式"
                              : "价格单位核算方式",
                          prop: "",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "核算方式" },
                            model: {
                              value: _vm.form.unit_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "unit_type", $$v)
                              },
                              expression: "form.unit_type",
                            },
                          },
                          _vm._l(_vm.numTypeSelect, function (i, idx) {
                            return _c("el-option", {
                              key: idx,
                              attrs: { value: i.id, label: i.name },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 2 },
                      model: {
                        value: _vm.form.is_back,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_back", $$v)
                        },
                        expression: "form.is_back",
                      },
                    },
                    [_vm._v(" 退货参与提成 ")]
                  ),
                  _vm.form.way >= 4 && _vm.form.way <= 9
                    ? _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 2 },
                          model: {
                            value: _vm.form.is_gift,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "is_gift", $$v)
                            },
                            expression: "form.is_gift",
                          },
                        },
                        [_vm._v(" 赠品参与提成 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.form.way == 1 || _vm.form.way == 4 || _vm.form.way == 7
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.form.way == 1 || _vm.form.way == 7
                              ? _vm.isXiao == 2
                                ? "销售提成比例"
                                : "送货提成比例"
                              : _vm.isXiao == 2
                              ? "销售提成金额"
                              : "送货提成金额",
                          prop: "",
                        },
                      },
                      [
                        _c(
                          "el-input",
                          {
                            model: {
                              value: _vm.form.sale_price,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "sale_price", $$v)
                              },
                              expression: "form.sale_price",
                            },
                          },
                          [
                            _c("template", { slot: "append" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.form.way == 1 || _vm.form.way == 7
                                      ? "%"
                                      : "元"
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm.form.is_back != 2
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                _vm.form.way == 1 || _vm.form.way == 7
                                  ? "退货提成比例"
                                  : "退货提成金额",
                            },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                model: {
                                  value: _vm.form.back_price,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "back_price", $$v)
                                  },
                                  expression: "form.back_price",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.form.way == 1 || _vm.form.way == 7
                                          ? "%"
                                          : "元"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm.showTable
                ? [
                    _c(
                      "el-table",
                      { attrs: { stripe: "", data: _vm.list } },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "start",
                            width: "340",
                            label: _vm.tableHeader,
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  var $index = ref.$index
                                  return [
                                    _c(
                                      "el-input",
                                      {
                                        staticStyle: { width: "180px" },
                                        attrs: {
                                          onkeyup:
                                            "value=value.replace(/[^\\d.]/g,'')",
                                          type: "text",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.tableNum(
                                              $event,
                                              $index,
                                              row
                                            )
                                          },
                                        },
                                        model: {
                                          value: row.start,
                                          callback: function ($$v) {
                                            _vm.$set(row, "start", $$v)
                                          },
                                          expression: "row.start",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "prepend" }, [
                                          _vm.form.way == 3 ||
                                          _vm.form.way == 6 ||
                                          _vm.form.way == 9
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                    color: "red",
                                                  },
                                                },
                                                [_vm._v(" > ")]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "5px",
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [_vm._v(" ≥ ")]
                                              ),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _vm.form.way == 3 ||
                                    _vm.form.way == 6 ||
                                    _vm.form.way == 9
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                              "font-size": "14px",
                                            },
                                          },
                                          [_vm._v(" 小于等于 ")]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                              "font-size": "14px",
                                            },
                                          },
                                          [_vm._v(" 小于 ")]
                                        ),
                                    _c(
                                      "span",
                                      { staticStyle: { "margin-left": "5px" } },
                                      [
                                        _vm._v(
                                          " " + _vm._s(row.end || "") + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1298572498
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "sale_price",
                            label:
                              _vm.isXiao == 2
                                ? "销售提成(" + _vm.isYuan + ")"
                                : "送货提成(" + _vm.isYuan + ")",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      staticStyle: { width: "100%" },
                                      attrs: { type: "number" },
                                      model: {
                                        value: row.sale_price,
                                        callback: function ($$v) {
                                          _vm.$set(row, "sale_price", $$v)
                                        },
                                        expression: "row.sale_price",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2959512868
                          ),
                        }),
                        _vm.form.is_back == 1
                          ? _c("el-table-column", {
                              attrs: {
                                prop: "back_price",
                                label: "退货提成(" + _vm.isYuan + ")",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("el-input", {
                                          staticStyle: { width: "100%" },
                                          attrs: { type: "number" },
                                          model: {
                                            value: row.back_price,
                                            callback: function ($$v) {
                                              _vm.$set(row, "back_price", $$v)
                                            },
                                            expression: "row.back_price",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1271846068
                              ),
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            prop: "",
                            align: "center",
                            label: "操作",
                            fixed: "right",
                            width: "110px",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var $index = ref.$index
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handleAddRow($index, row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 添加 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handleDeleteRow(
                                              $index,
                                              row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 删除 ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3371960163
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c("p", { staticStyle: { "margin-top": "10px" } }, [
                _vm._v(
                  " 提示：提成金额或比例输入负数代表扣钱，例如退货要扣1%提成，输入-1即可 "
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确定")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }