var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showDialog,
        width: "600px",
        top: "15vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-position": "right",
                "label-width": "120px",
                "label-suffix": ":",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "方案名称", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "305px" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "类型", prop: "type" } }, [
                _vm._v(_vm._s(_vm.typeName)),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "提成范围", prop: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: {
                        multiple: "",
                        placeholder: "全部下单方式(可多选)",
                      },
                      model: {
                        value: _vm.form.order_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "order_type", $$v)
                        },
                        expression: "form.order_type",
                      },
                    },
                    _vm._l(_vm.fanwei2, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.name },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px", "margin-left": "5px" },
                      attrs: {
                        multiple: "",
                        placeholder: "全部销售类型(可多选)",
                      },
                      model: {
                        value: _vm.form.goods_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "goods_type", $$v)
                        },
                        expression: "form.goods_type",
                      },
                    },
                    _vm._l(_vm.fanwei1, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "限已结清", prop: "is_clear" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.form.is_clear,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_clear", $$v)
                        },
                        expression: "form.is_clear",
                      },
                    },
                    [_vm._v("是")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.form.is_clear,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_clear", $$v)
                        },
                        expression: "form.is_clear",
                      },
                    },
                    [_vm._v("否")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "0 auto", "text-align": "center" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确定")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }