var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-radio-group",
        {
          model: {
            value: _vm.type,
            callback: function ($$v) {
              _vm.type = $$v
            },
            expression: "type",
          },
        },
        [
          _c("el-radio-button", { attrs: { label: 2 } }, [
            _vm._v("销售提成方案"),
          ]),
          _c("el-radio-button", { attrs: { label: 3 } }, [
            _vm._v("送货提成方案"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "l" }, [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.handleCreatePlan },
                },
                [_vm._v(" 添加方案 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "warning" },
                  on: { click: _vm.handleEditPlan },
                },
                [_vm._v(" 编辑方案 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "left-table" },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    stripe: "",
                    data: _vm.leftList,
                    height: "480px",
                    "highlight-current-row": "",
                  },
                  on: { "cell-click": _vm.leftTableCellClick },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: _vm.xiaosong,
                      width: "",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.handleDeletePlan },
                },
                [_vm._v(" 删除方案 ")]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "r" }, [
          _c(
            "div",
            { staticClass: "form", staticStyle: { "margin-left": "10px" } },
            [
              _c("brand-select", {
                ref: "brandSelect",
                staticStyle: {
                  display: "inline-block",
                  width: "140px",
                  margin: "0 10px",
                },
                attrs: { duoxuan: true },
                on: { "brand-select-change": _vm.brandChange },
              }),
              _c("el-input", {
                staticStyle: { display: "inline-block", width: "160px" },
                attrs: { placeholder: "请输入商品名称、简拼" },
                model: {
                  value: _vm.form.goods_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "goods_name", $$v)
                  },
                  expression: "form.goods_name",
                },
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v(" 查询已添加商品 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v(" 添加商品 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "success" },
                  on: { click: _vm.handleExportAll },
                },
                [_vm._v(" 导出全部商品 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "success" },
                  on: { click: _vm.handleExportE },
                },
                [_vm._v(" 导出提成商品 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "info" },
                  on: { click: _vm.handleImportE },
                },
                [_vm._v(" 导入提成商品 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table", staticStyle: { "margin-left": "20px" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.rightLoading,
                      expression: "rightLoading",
                    },
                  ],
                  ref: "rightTable",
                  attrs: {
                    stripe: "",
                    data: _vm.rightList,
                    height: "480px",
                    "row-key": "id",
                  },
                  on: {
                    select: _vm.handleSelectRow,
                    "select-all": _vm.selectAll,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      type: "selection",
                      width: "50",
                      "reserve-selection": "",
                    },
                  }),
                  _vm._l(_vm.columns, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: item.width,
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                item.label == "销售提成" ||
                                item.label == "送货提成"
                                  ? _c("div", [
                                      row.between.length > 0
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.ticheng(
                                                    row.between,
                                                    row.is_yuan == 1
                                                      ? "元"
                                                      : "%"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("div", [
                                            _vm._v(
                                              " " + _vm._s(row.sale_price) + " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: row.sale_price,
                                                    expression:
                                                      "row.sale_price",
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.is_yuan == 1
                                                        ? "元"
                                                        : "%"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                    ])
                                  : _vm._e(),
                                item.label == "退货提成"
                                  ? _c("div", [
                                      row.between.length > 0
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.ticheng2(
                                                    row.between,
                                                    row.is_yuan == 1
                                                      ? "元"
                                                      : "%"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("div", [
                                            _vm._v(
                                              " " + _vm._s(row.back_price) + " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: row.back_price,
                                                    expression:
                                                      "row.back_price",
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.is_yuan == 1
                                                        ? "元"
                                                        : "%"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                    ])
                                  : _vm._e(),
                                item.label == "商品名称"
                                  ? _c(
                                      "div",
                                      [
                                        _c("goods-search", {
                                          ref: "goodsSearch",
                                          refInFor: true,
                                          attrs: { "f-key": row.goods_name },
                                          on: {
                                            "add-rows": function ($event) {
                                              return _vm.addRows($event, row)
                                            },
                                            "select-goods-all": function (
                                              $event
                                            ) {
                                              return _vm.goodsSelect(
                                                $event,
                                                row
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      align: "center",
                      label: "操作",
                      fixed: "right",
                      "min-width": "120px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            row.goods_id
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handleEdit($index, row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 设置 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handleDelete($index, row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 删除 ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "botton-tools" },
                [
                  _c(
                    "div",
                    { staticClass: "btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleBatchSet },
                        },
                        [_vm._v(" 批量设置 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger" },
                          on: { click: _vm.handleBatchDelete },
                        },
                        [_vm._v(" 批量删除 ")]
                      ),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.form.pageNo,
                      layout: _vm.layout,
                      "page-size": _vm.form.pageSize,
                      total: _vm.total,
                      background: "",
                    },
                    on: {
                      "current-change": _vm.handleCurrentChange,
                      "size-change": _vm.handleSizeChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("add-plan", {
        ref: "addPlan",
        on: {
          refresh: function ($event) {
            return _vm.leftTableData(_vm.type)
          },
        },
      }),
      _c("setPlan", { ref: "setPlan", on: { refresh: _vm.fetchData } }),
      _c("import-tpl", { ref: "importTpl" }),
      _c("GoodsChooseDialog", {
        ref: "GoodsChooseDialog",
        on: { "add-rows": _vm.addRows },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }